<template>

    <div class="container">
        <h3 class="font-weight-bold mb-4 centers">FRAIS ET CONDITIONS D’ADOPTION</h3>

        <hr class="all">
        <br>
        <div class="row margetop">


            <div class="col-lg-12">
                <img class="test" src="../assets/images/tarif.png" alt="Devenir Bénévole, soutenir la spa de savoie">
            </div>
            <br><br>
            <br><br>

            <h3 class="font-weight-bold mb-4 justus">
                <br><br>
                Nos tarifs comprennent
            </h3>

            <p class="justu">

                <u>Pour les chats :</u>
                <br>
                Test FELV/FIV, première vaccination TC, stérilisation/castration, identification, certificat de bonne
                santé.
                <br><br>

                <u>Pour les chiens :</u><br>

                Première injection de vaccins CHPL, stérilisation/castration, identification, certificat de bonne santé.

                <br><br>
                NOUS VOUS RAPPELONS QUE LA S.P.A DE SAVOIE N’ACCEPTE PAS LES RÈGLEMENTS PAR CHÈQUE.

                Nous plaçons nos animaux en Savoie et départements limitrophes uniquement.
            </p>


            <br>
            <br>


        </div>
    </div>


    <br>
    <br>

</template>


<style src="../assets/css/animaux.css"></style>
