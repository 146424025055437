<template>
    <div class="container-fluid">

        <div class="container">
            <h3 class="font-weight-bold mb-4 centers" id="top">La boutique</h3>
            <hr class="all">
            <div class="mid">
                <p class="first_title"> Notre boutique est une source de revenus primordiale pour l association.
                    <br>
                    Les produits ci-dessous sont disponibles au refuge.
                    <br>
                    <br>
                </p>
            </div>
        </div>


        <div class="row">
            <div class="col-lg-3" v-for="shop in  shops " v-bind:key="shop.id">
                <div class="card">
                    <img :src="'https://www.admin.spadesavoie.fr/images/' + shop.img1"
                        :alt="'photo de la boutique de la spa de Savoie  / Chambery - Auvergne-Rhône-Alpes'"
                        :title="shop.name"
                        style="height:400px;display: block;margin-left: auto;margin-right: auto; width: 100%;" />
                    <div class="container titlecontainershop" style="margin-top: 20px;">
                        <h4><b>{{ shop.name }}</b></h4>
                        <br>
                        <h5 v-html="shop.resumer"></h5>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <br>
        <br>
        <br>
        <br><br>
        <br>
        <br>
        <br>

    </div>

</template>

<style src="../assets/css/boutique.css"></style>
<script>
export default {
    data() {
        return {
            shops: [],
        };
    },
    mounted() {
        fetch('https://www.admin.spadesavoie.fr/boutique')
            .then(response => response.json())
            .then(data => {
                this.shops = data.shop; // Mettez à jour chiens avec les données de l'API
            })
            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });
    }
};

</script>
