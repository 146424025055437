<template>
    <div style="width: 100%">
        <img class="transiabso" src="../assets/images/border-bottom-header.png" alt="image de decoration">
        <iframe scrolling="no" marginheight="0" marginwidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=744,%20rue%20de%20Montagny,%2073000%20Chamb%C3%A9ry+(S.P.A%20Savoie)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            width="100%" height="600" frameborder="0"></iframe><a
            href="https://www.maps.ie/draw-radius-circle-map/">Google radius map</a>

        <img class="transibot" src="../assets/images/bottransi.png" alt="image de decoration">
    </div>
    <br />



    <div class="container">
        <h3 class="font-weight-bold mb-4 centers">Nous contacter</h3>
        <hr class="all">
    </div>

    <footer class="page-footer font-small unique-color-dark">

        <div class="container text-center text-md-left mt-5">
            <div class="row mt-3">
                <div class="col-md-4 col-lg-5 col-xl-4 mx-auto mb-7">
                    <h6 class="text-uppercase font-weight-bold centersss">Refuge S.P.A de Savoie</h6>
                    <hr class="allcontact">
                    <p><strong>Association de protection animale</strong>
                        <strong> Chambéry - Savoie</strong>
                        <br> 744, rue de Montagny
                        <br> 73000 Chambéry
                        <br>
                    </p>

                </div>
                <div class="col-md-5 col-lg-5 col-xl-5 mx-auto mb-7">
                    <h6 class="text-uppercase font-weight-bold centerss">Ouverture</h6>
                    <hr class="allcontact">
                    <p>
                        <i><u><strong>Les horaires d'ouverture: </strong></u></i>
                        <br> Lundi, mardi, mercredi, vendredi et samedi après-midi de 14h - 17h45‌
                    </p>
                    <p>
                        Fermé les jeudis, dimanches et jours fériés.
                    </p>



                </div>
                <div class="col-md-3 col-lg-5 col-xl-3 mx-auto mb-7">

                    <h6 class="text-uppercase font-weight-bold centerss">Contact</h6>
                    <hr class="allcontact">

                    <p>
                        <i><u><strong>Mails: </strong></u></i>
                        <br>
                        accueil@spa-savoie.com
                        chatadoption.spadesavoie@gmail.com
                        benevole.spadesavoie@gmail.com
                        enquetes@spa-savoie.com
                        parrainage.spa.savoie@gmail.com
                    </p>
                    Tél. 04 79 33 24 44
                    <p class="retr"> Retrouvez nous sur : </p>


                    <a target="blank" href="https://www.facebook.com/spadesavoie/">
                        <img src="../assets/images/fb.png" width="40px"
                            alt="facebook de la spa de savoie  / savoie chambery "></a>
                </div>

            </div>

        </div>
        <br><br>

        <div class="footer-copyright text-center py-3 plusbeau" id="contact">

            <a href="/legal" class="legalmention">
                Mentions légales</a>
            <div class="moi"><br> © 2021 Copyright - Refuge S.P.A de Savoie - Chambery </div>
        </div>


    </footer>
</template>

<style src="../assets/css/footer.css"></style>
