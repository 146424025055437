<template>
  <nav class="navbar navbar-expand-lg navbar-light " style="width: 100%;top: 0px;">
    <a href="/">
      <img class="logo" src="../assets/images/newlogo.png" width="200px"
        alt="logo de la spa de la spa de Savoie  / Chambery - Auvergne-Rhône-Alpes">
    </a>

    <a href="/" class="logotexte">
      <h1> <span class="margegauche">Refuge SPA - Savoie</span></h1>
    </a>
    <button @click="toggleNavbarDisplay()" class="navbar-toggler" type="button" data-toggle="collapse"
      data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse groupemid" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item ">
          <router-link class="nav-link" to="/" @click="toggleNavbar()">Accueil</router-link>
        </li>
        <p class="nav-link taille"><i class="fas fa-paw"></i></p>
        <li class="nav-item">
          <router-link class="nav-link" to="/besoins" @click="toggleNavbar()">Nos Besoins</router-link>
        </li>
        <p class="nav-link taille"><i class="fas fa-paw"></i></p>
        <li class="nav-item">
          <router-link class="nav-link" to="/animaux" @click="toggleNavbar()">Nos Animaux </router-link>
        </li>
        <p class="nav-link taille"><i class="fas fa-paw"></i></p>
        <li class="nav-item">
          <router-link class="nav-link" to="/sos" @click="toggleNavbar()">Sos </router-link>
        </li>
        <p class="nav-link taille"><i class="fas fa-paw"></i></p>
        <li class="nav-item">
          <router-link class="nav-link" to="/adopte" @click="toggleNavbar()">Les Adoptés </router-link>
        </li>
        <p class="nav-link taille"><i class="fas fa-paw"></i></p>
        <li class="nav-item">
          <router-link class="nav-link" to="/partenaire" @click="toggleNavbar()">Nos partenaires </router-link>
        </li>
        <p class="nav-link taille"><i class="fas fa-paw"></i></p>
        <li class="nav-item">
          <router-link class="nav-link" to="/boutique" @click="toggleNavbar()">Boutique </router-link>
        </li>
        <p class="nav-link taille"><i class="fas fa-paw"></i></p>
        <li class="nav-item">
          <router-link class="nav-link" to="/maltraitance" @click="toggleNavbar()">Maltraitance</router-link>
        </li>
        <p class="nav-link taille"><i class="fas fa-paw"></i></p>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ path: 'besoins', hash: '#don' }" @click="toggleNavbar()">Faire un
            don</router-link>
        </li>
        <p class="nav-link taille"><i class="fas fa-paw"></i></p>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ path: '/', hash: '#actuID' }"
            @click="toggleNavbar()">Actualités</router-link>
        </li>
        <p class="nav-link taille"><i class="fas fa-paw"></i></p>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ path: '/', hash: '#contact' }"
            @click="toggleNavbar()">Contact</router-link>
        </li>
      </ul>
    </div>
  </nav>

  <div class="main_slider testheaderpicture"
    style="background-image:url('/assets/images/spapetit.jpg'); height: 450px;">
    <!--    <div class="main_slider testheaderpicture" style="background-image:url(/images/catanddog.jpg)"> -->

    <img class="transi" src="/assets/images/border-bottom-header.png" alt="header">
    <div class="container fill_height">
      <div class="row align-items-center fill_height">
        <div class="col">
        </div>
      </div>
    </div>
    <img class="transibot" src="/assets/images/bottransi.png" alt="image de decoration">

  </div>
  <!-- route outlet -->
  <!-- component matched by the route will render here -->
  <router-view></router-view>
</template>

<style src="../assets/css/main_styles.css"></style>
<style src="../assets/css/header.css"></style>



<script>
export default {
  methods: {
    toggleNavbar() {
      if (window.innerWidth < 500) {
        var navbarToggler = document.querySelector('.navbar-toggler');
        navbarToggler.click();
      }
    },
    toggleNavbarDisplay() {
      if (window.innerWidth < 500) {
        var navbarToggler = document.querySelector('.navbar-toggler');
        navbarToggler.click();
      }
    },
  }
}

</script>