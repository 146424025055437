<template>

    <div class="container">
        <h3 class="font-weight-bold mb-4 centers">Nos Besoins</h3>

        <hr class="all">
        <br>
        <div class="row margetop" id="don">
            <div class="col-lg-7"> <img class="test" src="../assets/images/rond10.jpg"
                    alt="Devenir Bénévole, soutenir la spa de haute saone / franche comté"></div>
            <div class="col-lg-5">
                <h3 class="font-weight-bold mb-4 justus">Le don financier </h3>

                <p class="justu">Une façon simple d'aider le refuge est de faire un don financier, quelque soit la
                    somme, toute aide est bienvenue.
                    <br>
                    <br>
                    Pour cela vous pouvez:
                    <br>
                    <i class="fas fa-paw"></i> Utiliser le bouton Paypal ci-dessous
                    <br>
                    <i class="fas fa-paw"></i> <a href="../assets/images/soutien.docx" target="blank"> Réaliser un
                        virement </a>
                    <br>
                    <i class="fas fa-paw"></i> <a href="../assets/images/soutien.docx" target="blank"> Bon de
                        soutiens</a>
                    <br>
                    <i class="fas fa-paw"></i> Faire un chèque à l’ordre de la S.P.A. de Savoie
                    <br>
                    <i class="fas fa-paw"></i> Venir au refuge pour réaliser votre don en personne.
                </p>
                <p class="justu"></p>
                <br>
                <br>
                <br> <br>
                <div class="red_button shop_now_button buttonadaptatif bluenow"><a
                        href="https://www.paypal.com/donate/?hosted_button_id=49BSB76ZN8BR4&cmd=_s-xclick"
                        style="font-weight:bold;line-height: 40px;">Faire un don</a></div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row margetop">
            <div class="col-lg-5">
                <h3 class="font-weight-bold mb-4 justus">L’adhésion</h3>
                <span class="justu">Vous souhaitez concrétiser un acte solidaire par l’adhésion à notre association.
                    Vous devenez adhérent en vous acquittant d’une cotisation annuelle de 30€, si vous souhaitez donner
                    plus cela sera considéré comme un don et vous donnera droit à un abattement fiscal de 66%.</span>


                <span class="justu">Une fois adhérent, vous êtes convoqués aux assemblées générales ordinaires et
                    extraordinaire qui peuvent avoir lieu au cours de l’année. Vous pouvez participer à la vie de
                    l’association tout au long de l’année en assistant aux assemblées générales, en devenant
                    administrateur, ….</span>
                <br>
                <br>


                <span class="justu">Alors n’hésitez plus ce geste nous aide pour le quotidien de nos pensionnaires et
                    nous permet d’en sauver le plus possible, venez nous rejoindre dans notre lutte contre la souffrance
                    animale et les abandons. Être adhérent, c’est un peu devenir ambassadeur du refuge !</span>

                <br>
                <br>
                <br><br>
                <br>
            </div>
            <br>
            <br><br>
            <br>
            <div class="col-lg-7"> <img class="test" src="../assets/images/rond11.jpg" alt="refuge spa Savoie"></div>
        </div>
    </div>


    <div class="container">
        <div class="row margetop">
            <div class="col-lg-7"> <img class="test" src="../assets/images/rond12.jpg"
                    alt="Devenir Bénévole, soutenir la spa de haute saone / franche comté"></div>
            <div class="col-lg-5">
                <h3 class="font-weight-bold mb-4 justus">Le don d’objet, de nourriture, de litière</h3>


                <i> Tout ce que vous donnez, nous n’aurons pas à l’acheter ! </i>
                <br>
                <br>

                <i class="fas fa-paw"></i> Litière pour chat et NAC
                <br>
                <i class="fas fa-paw"></i> Nourriture : croquettes, pâtée, alimentation spécialisée
                <br>
                <i class="fas fa-paw"></i> Lait maternisé pour chaton
                <br>
                <i class="fas fa-paw"></i> Foin
                <br>
                <i class="fas fa-paw"></i> Friandises… Mais aussi jouets, de type kong ou corde pour nos chiens ;
                souris, balles, plumeaux pour les chats.
                <br>
                <i class="fas fa-paw"></i> Couvertures, plaids, polaires.
                <br>
                <i class="fas fa-paw"></i> Serviettes de bain.
                <br>
                <i class="fas fa-paw"></i> Laisses, colliers,
                <br>
                <i class="fas fa-paw"></i> Etc
                <br>
                <br>


                <span class="justu">Vous pouvez aussi faire don d’objet en bon état à vendre, des dons pour les lots de
                    nos portes ouvertes etc. N’hésitez pas à vous renseigner auprès de l’accueil pour tout don que vous
                    souhaiteriez faire. </span>
                <br>
                <br>

                <span class="justu">Important : dans notre démarche de partenariat, nous apportons notre soutien aux
                    autres associations de la cause animales et faisons le lien en cas de besoin. En effet, suivant nos
                    possibilités, nous tendons la main aux autres défenseurs de la cause animale en leur offrant
                    nourriture, cage, matériel… </span>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div class="container">
        <div class="row margetop">
            <div class="col-lg-5">
                <h3 class="font-weight-bold mb-4 justus" id="parrainage">Le parrainage</h3>
                <span class="justu"> Une belle manière d’apporter votre aide est de devenir parrain-marraine d’un ou de
                    plusieurs de nos pensionnaires, ceci consiste à mettre en place un versement périodique
                    (mensualisation…) d’une somme d’argent régulière au refuge qui sera destinée aux soins du ou des
                    animaux parrainés. Certains de nos pensionnaires ont des besoins très spécifiques (alimentation
                    spécialisé, traitement, soin médicaux…), autant de dépenses supplémentaires pour le refuge mais
                    indispensables pour l’animal. Vous serez régulièrement informé de l’évolution de la situation de
                    l’animal parrainé, et serez prévenu dès qu’il sera adopté, vous pourrez aussi lui rendre visite au
                    refuge. </span>
                <br>
                <br>

                <span class="justu">Un soutien financier non négligeable pour notre association et une façon de devenir
                    acteur, au moins un peu, de la vie de nos pensionnaires.</span>


                <i> Pour parrainer : </i>
                <br>
                <br>

                <i class="fas fa-paw"></i> <a href="https://forms.gle/XAq93c9XN78KxHm29"> Remplir ce formulaire de
                    parrainage </a>
                <br>
                <i class="fas fa-paw"></i> Mettre en place votre don par virement, paypal, virement automatique, chèque…
                <br>

                <p><i class="fas fa-paw"></i> <u>Pour plus d’informations sur le parrainage : </u>
                    parrainage.spa.savoie@gmail.com</p>
                <br>
                <br><br>
                <br>

            </div>
            <br>
            <br><br>
            <br>
            <div class="col-lg-7"> <br><br><img class="test" src="../assets/images/rond13.jpg"
                    alt="refuge animalier spa Savoie">
            </div>
        </div>
    </div>


    <div class="container">
        <div class="row margetop">
            <div class="col-lg-7"> <img class="test" src="../assets/images/rond14.jpg"
                    alt="Devenir Bénévole, soutenir la spa de Savoie département du Chambery, region de Auvergne-Rhône-Alpes">
            </div>
            <div class="col-lg-5">
                <h3 class="font-weight-bold mb-4 justus">Les legs et héritages</h3>
                <span class="justu">Qu’est ce qu’un legs ? Un legs est un don, argent, bien mobilier ou immobilier,
                    objet de valeur – que l’on fait par disposition testamentaire ou par une assurance-vie. Un notaire
                    peut vous conseiller dans ce domaine. La transmission se fait au décès du testateur. Quelles sont
                    les différentes formes de legs ?</span>

                <br>
                <br>

                <i class="fas fa-paw"></i> <b>Le legs universel :</b> vous transmettez l’intégralité de votre patrimoine
                (mobilier et immobilier)
                <br>
                <i class="fas fa-paw"></i> <b>Le legs particulier :</b> vous transmettez une partie de vos biens
                particuliers
                <br>
                <span class="justu">La façon la plus simple de prévoir sa succession est de faire un testament. Il
                    existe deux formes de testament :</span>

                <i class="fas fa-paw"></i> <b>Olographe :</b> c’est le plus simple et le plus répandu. Il doit être
                entièrement écrit, daté et signé de votre main.
                <br>
                <i class="fas fa-paw"></i> <b>Authentique :</b> c’est le plus sûr. Vous dictez votre testament
                devant un notaire en présence de deux témoins ou devant deux notaires.
                <br>
                <br>

                <span class="justu"> Il est recommandé de déposer le testament chez un notaire et de nous informer.
                    Nous pouvons nous charger nous-mêmes du dépôt du testament chez notre notaire habituel et de
                    l’inscription au Répertoire National. <br>Exemple de rédaction d’un testament en faveur de la
                    S.P.A DE SAVOIE : </span>
                <span class="justu">« Je soussigné… (nom, prénoms, adresse, date et lieu de naissance) institue pour
                    légataire universel M… (nom, prénoms, adresse) à charge pour lui verser à titre de legs
                    particuliers à la «S.P.A. de Savoie», Association reconnue d’utilité publique, dont les bureaux
                    sont à Chambéry 744 route de Montagny 73000 Chambery, une somme de …. (ou tel immeuble, ou telle
                    autre chose) nette de tous frais. Je révoque toute disposition testamentaire antérieure</span>

                <br>
                <br><br>
                <br>
            </div>
        </div>
    </div>



    <div class="container">
        <div class="row margetop">
            <div class="col-lg-5">
                <h3 class="font-weight-bold mb-4 justus">Le bénévolat</h3>
                <span class="justu"> Vous avez un peu de temps libre? Vous souhaitez vous engager dans la protection
                    animale ? Alors venez rejoindre notre équipe de bénévoles à la S.P.A. de Savoie. </span>
                <br>
                <br>

                Nous recherchons tous types de profil de bénévole :
                <br>
                <br>

                <i class="fas fa-paw"></i> Aider au nettoyage des unités chiens et chats
                <br>
                <i class="fas fa-paw"></i> Aider à l’accueil
                <br>
                <i class="fas fa-paw"></i> Devenir Famille d’accueil pour chats
                <br>
                <i class="fas fa-paw"></i> Participer aux animations extérieures (collecte, foire, sensibilisation…)
                <br>
                <i class="fas fa-paw"></i> Bricoler et aider aux travaux dans le refuge …
                <br>
                <i class="fas fa-paw"></i> Être enquêteur : visites après placement et / ou maltraitance
                <br>
                <i class="fas fa-paw"></i> Gestion comptable
                <br>
                <i class="fas fa-paw"></i> Gestion administrative et secrétariat
                <br>
                <i class="fas fa-paw"></i> Etc

                <br>
                <br>
                <br>

                <span class="justu"> Pour cela, rien de plus simple, contactez-nous par mail en expliquant votre
                    démarche, vos motivations et vos compétences et nous reprendrons contact avec vous dans les plus
                    brefs délais.</span>

                <br>
                <br>

                <a href="mailto:benevole.spadesavoie@gmail.com"> benevole.spadesavoie@gmail.com </a>

            </div>
            <div class="col-lg-7"> <br><br><img class="test" src="../assets/images/rond15.jpg"
                    alt="refuge animalier spa Savoie">
            </div>
        </div>
    </div>


    <br>
    <br>
    <br>
</template>


<style src="../assets/css/besoin.css"></style>
