<template>

    <div class="container">
        <h3 class="font-weight-bold mb-4 centers">Signalement</h3>

        <hr class="all">
        <br>
        <div class="row margetop" id="don">
            <div class="col-lg-5">
                <img class="test noresponsive" src="../assets/images/img1signalement1.png" alt="">
                <br><br>
                <img class="test noresponsive" src="../assets/images/img1signalement2.png" alt="">
            </div>
            <div class="col-lg-7 topresponsive">
                <h3 class="font-weight-bold mb-4 justus">Vous êtes témoin de maltraitance ? Vous souhaitez faire un
                    signalement ? </h3>

                <p class="justu">La S.P.A de Savoie envoie des bénévoles enquêteurs dans toute la Savoie afin de
                    contrôler les conditions de vie des animaux faisant l'objet de signalements, de discuter avec les
                    propriétaires pour améliorer les conditions de vie de ces animaux,
                    et dans les cas extrêmes de lancer les procédures pour que les animaux soient retirés à leur
                    propriétaire et confiés au refuge.
                    <br>
                    <br>
                    Vous pouvez faire un signalement en suivant cette procédure :
                    <br>

                    <i class="fas fa-paw"></i> Télécharger ce formulaire :
                    <br><a
                        href="https://drive.google.com/file/d/1Qxdt1Vt6fpRB2peieVWJNVlndLQsAzVW/view">https://drive.google.com/file/d/1Qxdt1Vt6fpRB2peieVWJNVlndLQsAzVW/view</a>
                    <br><i class="fas fa-paw"></i> Le compléter sur votre ordinateur et le renvoyer à l'adresse :
                    enquetes@spa-savoie.com
                    <br>
                    <i class="fas fa-paw"></i> Venir au refuge pour réaliser votre signalement en personne.
                </p>

                <br>
                <br>
                <h3 class="font-weight-bold mb-4 justus">Qu'est ce que la maltraitance ou la négligence ?</h3>
                <p class="justu">


                    <i class="fas fa-paw"></i> violence physique <br>

                    <i class="fas fa-paw"></i> conditions de vie inadaptées <br>

                    <i class="fas fa-paw"></i> trop grande quantité d'animaux <br>

                    <i class="fas fa-paw"></i> absence de soins (vétérinaires, nourriture, eau, hygiène, ne pas répondre
                    à
                    ses besoins...) <br>

                    <i class="fas fa-paw"></i> abandon <br>

                    <i class="fas fa-paw"></i>... <br>
                    <br>
                    Vous pouvez les aider, pour cela il ne faut pas fermer les yeux et signaler les faits aux
                    associations ou aux autorités compétentes.
                </p>
                <br>


            </div>
        </div>
    </div>





    <br>
</template>


<style src="../assets/css/besoin.css"></style>
