import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import App from './App.vue';
import Home from './view/homeVue.vue';
import Besoins from './view/besoinsVue.vue';
import Animaux from './view/animauxVue.vue';
import Condition from './view/conditionAdoptionVue.vue';

import Partenaire from './view/partenaireVue.vue';
import Adopte from './view/adopteVue.vue';
import AnimalPage from './view/animauxDetails.vue';
import NewsPage from './view/newsPage.vue';
import LegalPage from './view/legalVue.vue';
import Sos from './view/sosVue.vue';
import Boutique from './view/boutiqueVue.vue';
import Parrainage from './view/parrainageVue.vue';
import Signalement from './view/signalementVue.vue';




const routes = [
    { path: '/redirect', redirect: '/' },
    { path: '/', component: Home },
    { path: '/besoins', component: Besoins },
    { path: '/animaux', component: Animaux },
    { path: '/sos', component: Sos },
    { path: '/conditions', component: Condition },
    { path: '/parrainage', component: Parrainage },
    { path: '/partenaire', component: Partenaire },
    { path: '/adopte', component: Adopte },
    { path: '/boutique', component: Boutique },
    { path: '/animaux/:type/:id', component: AnimalPage },
    { path: '/publication/:id', component: NewsPage },
    { path: '/legal', component: LegalPage },
    { path: '/maltraitance', component: Signalement }

];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth'
            };
        } else {
            window.scrollTo(0, 0);
        }
    }
});
createApp(App).use(router).mount('#app');