<template>
    <div class="container">
        <h3 class="font-weight-bold mb-4 centers">Mentions légales</h3>
        <hr class="all">
        <br><br>
        <div class="mid">
            <label>Nom de l’association :</label> Refuge S.P.A Savoie / Chambery <br><br>
            <label>Adresse :</label> 744, rue de Montagny <br><br>
            <label>Hébergeur :</label> OVH <br><br>
            <label>Le Refuge SPA Dunkerque ne stocke aucune information personnelle à partir du site</label>
            <br><br><br><br>
        </div>
    </div>
</template>