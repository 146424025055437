<template>
    <div class="container-fluid">

        <h3 class="font-weight-bold mb-4 centers">Nos animaux</h3>
        <div class="petiteinfo">Selon les dates de mise à jour, certains animaux visibles sur le site ont déjà pu
            être adoptés <br>N'hésitez pas à nous contacter par mail accueil@spa-savoie.com

            <br>
            <br>
            <a href="https://www.facebook.com/spadesavoie/posts/2410307155937397"> Procédure d'adoption </a>
            <a href="/conditions" style="margin-left:50px"> Frais d'adoption </a>

            <br>
            <br>
            <hr class="all">
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-3">
                    <div class="red_button shop_now_button buttonadaptatif bluenow2 aliasdogandcat colorBouton"
                        @click="filtrerAnimaux('chiens')">Chiens</div>
                </div>
                <div class="col-lg-3">
                    <div class="red_button shop_now_button buttonadaptatif bluenow2 aliasdogandcat colorBouton"
                        @click="filtrerAnimaux('chats')">Chats
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="red_button shop_now_button buttonadaptatif bluenow2 aliasdogandcat colorBouton"
                        @click="filtrerAnimaux('lapins')">Nacs</div>
                </div>
                <div class="col-lg-3">
                    <div class="red_button shop_now_button buttonadaptatif bluenow2 aliasdogandcat colorBouton"
                        style="background:#9B6A6C !important">
                        <router-link to="/parrainage">Parrainage</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
        </div>
        <div class="row">
            <div class="col-lg-3" v-for="animau in  animauxFiltres " v-bind:key="animau.id" :class="animau.type">
                <router-link :to="'/animaux/' + animau.type + '/' + animau.id" target="_blank">
                    <span v-if="animau.race">
                        <img :src="animau.principalimage ? 'https://www.admin.spadesavoie.fr/images/' + animau.principalimage : 'https://www.admin.spadesavoie.fr/images/chienmanquant.png'"
                            :alt="'photo du chien ' + animau.name + ' de la spa de Savoie  / Chambery - Auvergne-Rhône-Alpes'"
                            :class="'tailleimg'" :title="animau.name + ' ' + animau.race ?? animau.couleur" style="" />
                    </span>
                    <span v-else-if="!animau.EntenteChat">
                        <img :src="'https://www.admin.spadesavoie.fr/images/' + animau.principalimage"
                            :alt="'photo du nacs ' + animau.name + ' de la spa de Savoie  / Chambery - Auvergne-Rhône-Alpes'"
                            :class="'tailleimg'" :title="animau.name + ' ' + animau.race ?? animau.couleur" style="" />
                    </span>
                    <span v-else-if="animau.couleur">
                        <img :src="'https://www.admin.spadesavoie.fr/images/' + animau.principalimage"
                            :alt="'photo du chat ' + animau.name + ' de la spa de Savoie  / Chambery - Auvergne-Rhône-Alpes'"
                            :class="'tailleimg'" :title="animau.name + ' ' + animau.race ?? animau.couleur" style="" />
                    </span>

                    <div class="endessous" style="margin-top:0px !important">
                        <h4 class="namedogtitleback">{{ animau.name }}</h4>
                        <h6 class="">
                            <strong>
                                <span v-if="animau.race">Race :</span>
                                <span v-else-if="animau.couleur">Couleur :</span>
                            </strong>
                            {{ animau.race ?? animau.couleur }}
                        </h6>
                        <h6 class=""> <strong> Age: {{ calculateAge(animau.naissance) }}</strong>
                        </h6>
                        <i class="fas fa-plus-circle"></i>
                    </div>
                </router-link>
            </div>

        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
</template>

<style src="../assets/css/animaux.css"></style>
<script>
import convertAge from '../components/getAge.js';
export default {
    data() {
        return {
            animaux: [],
            animauxFiltres: []
        };
    },
    mounted() {
        fetch('https://www.admin.spadesavoie.fr/animaux')
            .then(response => response.json())
            .then(data => {
                this.animaux = data.animaux; // Mettez à jour chiens avec les données de l'API
                this.animauxFiltres = this.animaux;
            })
            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });
    },
    methods: {
        filtrerAnimaux(type) {
            this.animauxFiltres = this.animaux.filter(animau => animau.type === type);
        },
        calculateAge(dateOfBirth) {
            const age = convertAge(dateOfBirth)
            return age
        }
    }
};

</script>
