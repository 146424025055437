<template>
    <div class="container-fluid">

        <div class="container">
            <h3 class="font-weight-bold mb-4 centers">Les adoptés</h3>
            <hr class="all">
        </div>

        <div class="container">
        </div>
        <div class="row">
            <div class="col-lg-3" v-for="animau in  animauxFiltres " v-bind:key="animau.id" :class="animau.type">
                <span v-if="animau.race">
                    <img :src="'https://www.admin.spadesavoie.fr/images/' + animau.principalimage"
                        :alt="'photo du chien ' + animau.name + ' de la spa de Savoie  / Chambery - Auvergne-Rhône-Alpes'"
                        :class="'tailleimg'" :title="animau.name + ' ' + animau.race ?? animau.couleur" style="" />
                </span>
                <span v-else-if="!animau.EntenteChat">
                    <img :src="'https://www.admin.spadesavoie.fr/images/' + animau.principalimage"
                        :alt="'photo du nacs ' + animau.name + ' de la spa de Savoie  / Chambery - Auvergne-Rhône-Alpes'"
                        :class="'tailleimg'" :title="animau.name + ' ' + animau.race ?? animau.couleur" style="" />
                </span>
                <span v-else-if="animau.couleur">
                    <img :src="'https://www.admin.spadesavoie.fr/images/' + animau.principalimage"
                        :alt="'photo du chat ' + animau.name + ' de la spa de Savoie  / Chambery - Auvergne-Rhône-Alpes'"
                        :class="'tailleimg'" :title="animau.name + ' ' + animau.race ?? animau.couleur" style="" />
                </span>

                <div class="endessous" style="margin-top:0px !important">
                    <h4 class="namedogtitleback">{{ animau.name }}</h4>
                    <h6 class="">
                        <strong>
                            <span v-if="animau.race">Race :</span>
                            <span v-else-if="animau.couleur">Couleur :</span>
                        </strong>
                        {{ animau.race ?? animau.couleur }}
                    </h6>
                    <h6 class=""> <strong> Age: {{ calculateAge(animau.naissance) }}</strong>
                    </h6>
                    <i class="fas fa-plus-circle"></i>
                </div>
            </div>

        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
</template>

<style src="../assets/css/animaux.css"></style>
<script>
import convertAge from '../components/getAge.js';
export default {
    data() {
        return {
            animaux: [],
            animauxFiltres: []
        };
    },
    mounted() {
        fetch('https://www.admin.spadesavoie.fr/adopte')
            .then(response => response.json())
            .then(data => {
                this.animaux = data.animaux; // Mettez à jour chiens avec les données de l'API
                this.animauxFiltres = this.animaux;
            })
            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });
    },
    methods: {
        filtrerAnimaux(type) {
            this.animauxFiltres = this.animaux.filter(animau => animau.type === type);
        },
        calculateAge(dateOfBirth) {
            const age = convertAge(dateOfBirth)
            return age
        }
    }
};

</script>
