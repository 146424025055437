<template>
    <div v-if="$route.params.type != 'lapins'">

        <div class="container">
            <h3 class="font-weight-bold mb-4 centers">Fiche de {{ animal.name }}</h3>
            <hr class="all">
            <br>

            <div class="row">
                <div class="col-lg-4">
                    <h5 class="titlename">{{ animal.name }} </h5>
                    <br>
                    <br>
                    <strong>
                        <b v-if="animal.race">Race :</b>
                        <b v-else-if="animal.couleur">Couleur :</b>
                    </strong>
                    {{ animal.race ?? animal.couleur }}
                    <br>
                    <br>
                    <b>Date naissance :</b> {{ formatdatenaissance }}
                    <br>
                    <br>
                    <b>Date d'arrivée :</b> {{ formatdatearrive }}
                    <br>
                    <br>
                    <b>Caractère :</b> {{ animal.caractere }}
                    <br>
                    <br>
                    <b>Entente avec chien :</b> {{ animal.Ententechien }}
                    <br>
                    <br>
                    <b>Entente avec chat :</b> {{ animal.Ententechat }}
                    <br>
                    <br>
                    <b>Entente avec enfant :</b> {{ animal.Ententekids }}
                    <br>
                    <br>
                </div>
                <div class="col-lg-8 responsive">
                    <div v-html="decode"></div>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-lg-6" v-if="animal.principalimage">
                            <img :src="'https://www.admin.spadesavoie.fr/images/' + animal.principalimage"
                                :alt="'photo du nacs ' + animal.name + ' de la spa de Savoie  / Chambery - Auvergne-Rhône-Alpes'"
                                :title="animal.name + ' ' + animal.race ?? animal.couleur" style="width: 100%;" />
                        </div>
                        <div class="col-lg-6" v-if="animal.image2">
                            <img :src="'https://www.admin.spadesavoie.fr/images/' + animal.image2"
                                :alt="'photo du nacs ' + animal.name + ' de la spa de Savoie  / Chambery - Auvergne-Rhône-Alpes'"
                                :title="animal.name + ' ' + animal.race ?? animal.couleur" style="width: 100%;" />
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>

        </div>

    </div>
    <div v-else-if="$route.params.type == 'lapins'">

        <div class="container">
            <h3 class="font-weight-bold mb-4 centers">Fiche de {{ animal.name }}</h3>
            <hr class="all">
            <br>

            <div class="row">
                <div class="col-lg-4">
                    <h5 class="titlename">{{ animal.name }} </h5>
                    <br>
                    <br>
                    <strong>
                        <b v-if="animal.race">Race :</b>
                        <b v-else-if="animal.couleur">Couleur :</b>
                    </strong>
                    {{ animal.race ?? animal.couleur }}
                    <br>
                    <br>
                    <b>Date naissance :</b> {{ formatdatenaissance }}
                    <br>
                    <br>
                    <b>Date d'arrivée :</b> {{ formatdatearrive }}
                    <br>
                    <br>
                    <b>Caractère :</b> {{ animal.caractere }}
                    <br>
                    <br>
                    <br>
                    <br>
                </div>
                <div class="col-lg-8 responsive">
                    <div v-html="decode"></div>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-lg-6" v-if="animal.principalimage">
                            <img :src="'https://www.admin.spadesavoie.fr/images/' + animal.principalimage"
                                :alt="'photo du nacs ' + animal.name + ' de la spa de Savoie  / Chambery - Auvergne-Rhône-Alpes'"
                                :class="'tailleimglapin'" :title="animal.name + ' ' + animal.race ?? animal.couleur"
                                style="width: 100%;" />
                        </div>
                        <div class="col-lg-6" v-if="animal.image2">
                            <img :src="'https://www.admin.spadesavoie.fr/images/' + animal.image2"
                                :alt="'photo du nacs ' + animal.name + ' de la spa de Savoie  / Chambery - Auvergne-Rhône-Alpes'"
                                :class="'tailleimglapin'" :title="animal.name + ' ' + animal.race ?? animal.couleur"
                                style="width: 100%;" />
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>

        </div>
    </div>

    <div class="red_button shop_now_button buttonadaptatif buttonarticle" style="color: white; padding-top: 6px;"
        @click="closeTab()">Retour</div>

</template>
<style src="../assets/css/descri_animaux.css"></style>
<script>
export default {
    data() {
        return {
            animal: {},
            decode: null,
            formatdatearrive: null,
            formatdatenaissance: null
        };
    },
    mounted() {
        fetch('https://www.admin.spadesavoie.fr/animaux/' + this.$route.params.type + '/' + this.$route.params.id)
            .then(response => response.json())
            .then(data => {

                this.animal = data.animal;
                this.decode = data.decode;
                this.formatdatearrive = data.formatdatearrive;
                this.formatdatenaissance = data.formatdatenaissance;

                //name =    this.animal = data.animal.name;
            })
            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });
    },
    methods: {
        closeTab() {
            window.close();
        },

    }
};

</script>
